import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Banner from "./components/Banner/Banner";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Gallery from "./pages/Gallery/Gallery";
import Content from "./pages/Content/Content";
import BabyYoda from "./components/BabyYoda/BabyYoda";
import UpButton from "./components/UpButton/UpButton";
import NotFound from "./pages/NotFound/NotFound";

import { useEffect } from "react";
import { trackScrollDepth, trackPageView } from "./utils/analytics";

// import Snitch from "./components/Snitch/Snitch"

function App() {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        ((window.scrollY + window.innerHeight) / document.body.scrollHeight) *
        100;

      if (scrollPosition >= 50) {
        trackScrollDepth(50);
      }
      if (scrollPosition >= 90) {
        trackScrollDepth(90);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);
  return (
    <Router>
      <Banner />
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/:id/:slug" element={<Content />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <BabyYoda />
      {/* <Snitch /> */}
      <UpButton />
      <Footer />
    </Router>
  );
}

export default App;
