import React from 'react'
import "./Banner.css"
import Marquee from "react-fast-marquee";
import { FaYoutube } from "react-icons/fa";
import { trackSocialClick } from "../../utils/analytics";


function Banner() {
  return (
    <section className="banner">
      <Marquee pauseOnHover speed={40}>
        <p className="banner-title">
          <a
            className="youtube"
            href="https://www.youtube.com/@poceansblue"
            target="_blank"
            rel="noreferrer"
            onClick={() => trackSocialClick("youtube-ban-text")}
          >
            دنبال کنید
          </a>
          یادتون نره یوتوب منو
          <a
            className="youtube-logo"
            href="https://www.youtube.com/@poceansblue"
            target="_blank"
            rel="noreferrer"
            onClick={() => trackSocialClick("youtube-ban-icon")}
          >
            <FaYoutube className="youtube-logo" />
          </a>
        </p>
      </Marquee>
    </section>
  );
}

export default Banner