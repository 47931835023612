import React, { useState, useEffect } from "react";
import "./UpButton.css";
import Broom from "../../assets/broomstick.png";
import { trackEvent } from "../../utils/analytics"; 


function UpButton() {
  const [showButton, setShowButton] = useState(false);
  const [flying, setFlying] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const scrollThreshold = 0.8;

    setShowButton(scrollY > scrollThreshold * windowHeight);
  };

  const handleButtonClick = () => {
    setFlying(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    trackEvent("up_button_clicked", {
      event_category: "User Behavior",
      event_label: "Up Button Clicked",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (flying) {
      setTimeout(() => {
        setFlying(false);
      }, 1000); // Adjust the timeout to control how long the broom stays visible during the flight
    }
  }, [flying]);

  return (
    <div
      onClick={handleButtonClick}
      className={`up-container ${showButton ? "show" : ""}`}
    >
      {showButton && (
        <img
          className={`broom ${flying ? "fly" : ""}`}
          src={Broom}
          alt="BroomStick"
        />
      )}
    </div>
  );
}

export default UpButton;
