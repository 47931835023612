import React from 'react'
import "./Gallery.css"
import BlueClubCard from './components/BlueClubCard'

import Data from "../../blueclubs.json"

import { Link, useNavigate } from "react-router-dom";

import { trackImageClick } from "../../utils/analytics";

function Gallery() {
  const reversedData = Data.slice(0, -1).reverse();
  const navigate = useNavigate();

  const handleClick = (id, slug) => {
    // Scroll to the top of the page when the link is clicked
    window.scrollTo(0, 0);

    // Navigate to the specified route using the `navigate` function
    navigate(`/${id}/${slug}`);
    trackImageClick(slug);
  };


  return (
    <section className="gallery-section">
      {reversedData.map((item) => (
        <Link
          to={`/${item.id}/${item.slug}`}
          key={item.id}
          onClick={() => handleClick(item.id, item.slug)}
        >
          <BlueClubCard
            bookCover={item.bookCover}
            alternativeText={item.alternativeText}
            slug={item.slug}
            id={item.id}
            blueclub={item.blueclub}
          />
        </Link>
      ))}
    </section>
  );
}

export default Gallery