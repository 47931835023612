// Generic tracking function
export const trackEvent = (
  eventName,
  eventCategory,
  eventLabel = "",
  eventValue = 0
) => {
  if (window.gtag) {
    window.gtag("event", eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
    });
  } else {
    console.warn(`Google Analytics not initialized: ${eventName}`);
  }
};

// 🔹 Track Page Views
export const trackPageView = (pagePath) => {
  if (window.gtag) {
    window.gtag("config", "GA_MEASUREMENT_ID", {
      page_path: pagePath,
    });
  } else {
    console.warn(`Google Analytics not initialized: Page View - ${pagePath}`);
  }
};

// 🔹 Track Navigation Clicks (Home, About, Gallery)
export const trackNavigationClick = (pageName) => {
  trackEvent("navigation_click", "Navigation", pageName);
};

// 🔹 Track Social Media Clicks (Instagram, Twitter, YouTube, Telegram)
export const trackSocialClick = (platform) => {
  trackEvent("social_click", "Social", platform);
};

// 🔹 Track Scroll Depth (50% & 90%)
export const trackScrollDepth = (percentage) => {
  trackEvent(
    `scroll_${percentage}`,
    "User Behavior",
    `Scrolled ${percentage}%`
  );
};

// 🔹 Track Baby Yoda Easter Egg Events
export const trackBabyYodaSeen = () => {
  trackEvent("baby_yoda_seen", "Easter Egg", "Baby Yoda Appeared");
};

export const trackBabyYodaClicked = () => {
  trackEvent("baby_yoda_clicked", "Easter Egg", "Baby Yoda Clicked");
};

// 🔹 Track Discount Code Copy Event
export const trackDiscountCodeCopied = () => {
  trackEvent("discount_code_copied", "E-commerce", "Discount Code");
};

// 🔹 Track Image Click in Gallery
export const trackImageClick = (imageUrl) => {
  trackEvent("image_clicked", "Gallery", imageUrl);
};

// 🔹 Track Book Navigation (Next/Previous)
export const trackBookNavigation = (direction, bookName) => {
  trackEvent("book_navigation", "Books", `${direction} - ${bookName}`);
};
