import React from 'react'
import "./Welcome.css"
import { FaYoutube, FaTwitter, FaInstagram, FaTelegram } from "react-icons/fa";
import { trackSocialClick } from "../../../../../../utils/analytics";


function Welcome() {
  return (
    <section className="welcome-section">
      <h1 className="welcome-title">بلوکلاب</h1>
      <p className="welcome-content">
        بلوکلاب یک باشگاه کتابخوانیِ کاملاً رایگانه با ۱۰۰۰+ نفر عضو که در اون
        ما ماهی یک کتاب رو با اعضا میخونیم، از طریق ایمیل و تلگرام با هم در
        ارتباطیم و در مورد کتاب صحبت می‌کنیم. در انتهای همخوانی هم یک جلسه‌ی
        آنلاین و یک نشست حضوری داریم که کتاب رو بررسی می‌کنیم و البته کلی فعالیت
        گروهیِ جذاب دیگه.
        <br />
        <span className="welcome-content-span">
          با پر کردن فرم به صورت رایگان بلوکلابی شو:
        </span>
      </p>
      <div className="socials-welcome">
        <a
          href="https://www.youtube.com/@poceansblue"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackSocialClick("youtube-hero")}
        >
          <FaYoutube className="social-icon-welcome youtube-icon-welcome" />
        </a>
        <a
          href="https://www.instagram.com/poceansblue/"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackSocialClick("instagram-hero")}
        >
          <FaInstagram className="social-icon-welcome instagram-icon-welcome" />
        </a>
        <a
          href="https://twitter.com/poceansblue"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackSocialClick("twitter-hero")}
        >
          <FaTwitter className="social-icon-welcome twitter-icon-welcome" />
        </a>
        <a
          href="https://t.me/blueclub18"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackSocialClick("telegram-hero")}
        >
          <FaTelegram className="social-icon-welcome telegram-icon-welcome" />
        </a>
      </div>
    </section>
  );
}

export default Welcome