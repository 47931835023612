/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Content.css";
import { useParams } from "react-router-dom";
import BookPlace from "../../components/BookPlace/BookPlace";
import Accordion from "./components/Accordion";
import { Link } from "react-router-dom";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import Data from "../../blueclubs.json";

import { trackBookNavigation } from "../../utils/analytics";

function Content() {
  const { id, slug } = useParams();

  //   Check if id is a valid number
  const parsedId = parseInt(id, 10);
  if (isNaN(parsedId)) {
    return <div>Invalid book ID.</div>;
  }

  // Check if slug is a valid non-empty string
  if (!slug || typeof slug !== "string" || slug.trim() === "") {
    return <div>Invalid book slug.</div>;
  }

  const selectedBook = Data.find((item) => item.slug === slug);

  if (!selectedBook) {
    return <div>Book not found.</div>;
  }

   const selectedBookIndex = Data.slice(0 , Data.length - 1).findIndex((item) => item.slug === slug);

   if (selectedBookIndex === -1) {
     return <div>Book not found.</div>;
   }

  const nextBookIndex = (selectedBookIndex + 1) % (Data.length - 1);
  const prevBookIndex = (selectedBookIndex - 1 + (Data.length - 1)) % (Data.length - 1);

  const nextBookSlug = Data[nextBookIndex].slug;
  const prevBookSlug = Data[prevBookIndex].slug;

  const nextBookId = (nextBookIndex + 1).toString();
  const prevBookId = (prevBookIndex + 1).toString();

  const {
    blueclub,
    bookCover,
    alternativeText,
    bookName,
    author,
    translator,
    publisher,
    summary,
    location,
    date,
    numberOfMembers,
    homeworks,
    images,
  } = selectedBook;


  return (
    <section className="content-section">
      <div className="content-container-one">
        <div className="navigation-buttons">
          <Link
            to={`/${prevBookId}/${prevBookSlug}`}
            className="prev-button"
            onClick={() => trackBookNavigation("Previous", bookName)}
          >
            قبلی
          </Link>
          <h1 className="content-title">{blueclub}</h1>
          <Link
            to={`/${nextBookId}/${nextBookSlug}`}
            className="next-button"
            onClick={() => trackBookNavigation("Next", bookName)}
          >
            بعدی
          </Link>
        </div>
      </div>

      <div className="content-container-two">
        <div className="content-details">
          <div className="flex">
            <div className="content-detail-flex">
              <div className="content-detail">
                <h3 className="content-detail-title">نام کتاب:</h3>
                <p className="content-detail-content">{bookName}</p>
              </div>

              <div className="content-detail">
                <h3 className="content-detail-title">نویسنده:</h3>
                <p className="content-detail-content">{author}</p>
              </div>

              {!!translator && (
                <div className="content-detail">
                  <h3 className="content-detail-title">مترجم:</h3>
                  <p className="content-detail-content">{translator}</p>
                </div>
              )}

              <div className="content-detail">
                <h3 className="content-detail-title">انتشارات:</h3>
                <p className="content-detail-content">{publisher}</p>
              </div>

              <div className="content-detail">
                <h3 className="content-detail-title">محل ملاقات:</h3>
                <p className="content-detail-content">{location}</p>
              </div>

              <div className="content-detail">
                <h3 className="content-detail-title">تاریخ:</h3>
                <p className="content-detail-content">{date}</p>
              </div>

              <div className="content-detail">
                <h3 className="content-detail-title">تعداد اعضا:</h3>
                <p className="content-detail-content">{numberOfMembers}</p>
              </div>
            </div>
            <BookPlace image={bookCover} alternativeText={alternativeText} />
          </div>

          <div className="content-detail-summary">
            <h3 className="content-detail-title-summary">خلاصه کتاب</h3>
            <p className="content-detail-content-summary">{summary}</p>
          </div>
        </div>
      </div>
      <Accordion homeworks={homeworks} />
      <div className="pic-gallery">
        <h1 className="content-title">گالری</h1>
        {images.length > 5 ? (
          <Gallery>
            {images.map((image, index) => (
              <Item
                key={index}
                original={image}
                thumbnail={image}
                width="800" // Reduce the width to make images smaller
                height="600" // Reduce the height to make images smaller
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={image}
                    style={{ maxWidth: "35%", borderRadius: "25px" }} // Use maxWidth to control image size
                  />
                )}
              </Item>
            ))}
          </Gallery>
        ) : (
          <p className="no-homework-message">عکس نداشتیم!</p>
        )}
      </div>
    </section>
  );
}

export default Content;
