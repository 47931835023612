import "./Header.css";
// import Logo from "../../assets/bluelogo.svg";
import Logo2 from "../../assets/bluelogo2.svg";

import { NavLink } from "react-router-dom";
import { trackNavigationClick } from "../../utils/analytics";

function Header() {
  
  return (
    <header>
      <nav>
        <NavLink to={"/"} onClick={() => trackNavigationClick("Home-logo")}>
          <img src={Logo2} alt="logo" className="logo" />
        </NavLink>

        <ul className="list">
          <li className="items">
            <NavLink
              to={"/about"}
              className="navigation-link"
              onClick={() => trackNavigationClick("About")}
            >
              درباره ما
            </NavLink>
          </li>
          <li className="items">
            <NavLink
              to={"/gallery"}
              className="navigation-link"
              onClick={() => trackNavigationClick("gallery")}
            >
              گالری
            </NavLink>
          </li>
          <li className="items">
            <NavLink
              to={"/"}
              className="navigation-link"
              onClick={() => trackNavigationClick("home-text")}
            >
              خانه
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
